import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../appContext";
import { getAuth, signOut } from "firebase/auth";
import "react-activity/dist/library.css";
import { Routes, Route } from "react-router-dom";
import NoPage from "./noPage";
import DashHome from "./dashHome";
import DashApprove from "./dashApprove";
import DashClient from "./dashClient";
import DashAds from "./dashAds";

export default function AdminStack() {
  const myContext = useContext(AppContext);
  const user = myContext.user;
  const [loading, setLoading] = useState(true);

  const handleLogout = async () => {
    const auth = getAuth();
    await signOut(auth).catch((error) => {
      console.log("header: SIGN OUT ERROR:", error);
    });
  };

  const getIP = async () => {
    let ip = "0.0.0.0";
    const res = await fetch("https://api.ipify.org?format=json");
    const data = await res.json();
    if (data) {
      ip = data.ip;
    }
    return ip;
  };

  useEffect(() => {
    const checkAuthorization = async () => {
      const ip = await getIP();
      const data = {
        email: user.email,
        ip: ip,
      };
      const url =
        "https://us-central1-snagm-b6068.cloudfunctions.net/checkAuthorization";
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        mode: "cors",
      });
      let authorized = false;
      const result = await res.json();
      console.log("result: ", result);
      if (result.authorized === "true") {
        authorized = true;
        setLoading(false);
      }
      if (!authorized) {
        alert("You are not authorized to access this page.");
        handleLogout();
      }
    };
    checkAuthorization();
  }, [user.email]);

  if (!loading) {
    return (
      <Routes>
        <Route index element={<DashHome />} />
        <Route path="/approve" element={<DashApprove />} />
        <Route path="/client" element={<DashClient />} />
        <Route path="/ads" element={<DashAds />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    );
  }
}
