import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { collectionCount } from "../services/firestore";

export default function CardApprovals() {
  const navigate = useNavigate();
  const [approvalsCount, setApprovalsCount] = useState(0);
  const [nothingToProcess, setNothingToProcess] = useState(true);

  useEffect(() => {
    const getCount = async () => {
      const count = await collectionCount("approvals");
      setApprovalsCount(count);
      if (count > 0) {
        setNothingToProcess(false);
      }
    };
    getCount();
  }, []);

  const runApprovals = () => {
    navigate("/admin/approve");
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Approvals</h3>
      </Row>
      <Row>
        <p className="search-text">
          You have <b>{approvalsCount}</b> pending approvals.
        </p>
      </Row>
      <Row className="campaign-row">
        <Button
          disabled={nothingToProcess}
          onClick={() => runApprovals()}
          className="button-campaign"
        >
          Run Approvals Process
        </Button>
      </Row>
    </Col>
  );
}
