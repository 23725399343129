import React, { useEffect, useState, useRef } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { Dots } from "react-activity";
import { makeLink } from "../services/svcFunctions";
import { uploadImage } from "../services/svcImages";
import { updateClient } from "../services/svcClient";
import { writeDocument } from "../services/firestore";

export default function CardClientManage(props) {
  const [clientData, setClientData] = useState({});
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.clientData) {
      setClientData(props.clientData);
      setLoading(false);
    }
  }, [props.clientData]);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = async (event) => {
    setUploading(true);
    const fileObj = event.target.files && event.target.files[0];
    //-- check we have a good object
    if (!fileObj) {
      return;
    }
    console.log("file type: ", fileObj.type);
    //-- check the file type
    if (fileObj.type !== "image/jpeg") {
      alert("file must be jpg only");
      inputRef.current.value = null;
      setUploading(false);
      return;
    }
    //-- check the file size at 2MB
    if (fileObj.size > 2097152) {
      alert("file must be less than 2MB");
      inputRef.current.value = null;
      setUploading(false);
      return;
    }
    //-- load the image into an img element
    const objectURL = URL.createObjectURL(fileObj);
    const img = document.createElement("img");

    //-- when the image is loaded, upload it
    img.onload = () => {
      uploadBanner(fileObj);
      URL.revokeObjectURL(img.src);
    };
    img.src = objectURL;
    event.target.value = null;
    setUploading(false);
  };

  const uploadBanner = async (fileObj) => {
    let filename = "";
    //-- upload to storage
    try {
      filename = await uploadImage(fileObj);
      console.log("uploaded: ", filename);
    } catch (err) {
      alert("uploadFile: ERROR: " + err.message);
      return;
    }
    //-- update client record
    const newClient = { ...clientData };
    newClient.logo = filename;
    const result = await updateClient(clientData.clientID, newClient);
    if (result !== "success") {
      alert("ERROR: " + result);
    }
    //-- update client lookup record
    const newLookup = {
      clientID: clientData.clientID,
      clientName: clientData.clientName,
      logo: filename,
      url: clientData.url,
    };
    const newLookupRecord = { data: newLookup };
    writeDocument("client-lookup", clientData.clientID, newLookupRecord);
    //-- update state
    setClientData(newClient);
    return null;
  };

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Manage Client</h3>
        </Row>
        <Row>
          <p>loading...</p>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Manage Client</h3>
      </Row>
      <Row className="title-row">
        <p className="client-title">{clientData.clientName}</p>
      </Row>
      <Row>
        <p>Created: {clientData.created}</p>
      </Row>
      <Row>
        <p>Zip Code: {clientData.clientZIP}</p>
      </Row>
      <Row className="big-top">
        <Image src={makeLink("images", clientData.logo)} />
      </Row>
      <Row className="campaign-row">
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
        />
        <Button
          disabled={uploading}
          className="med-top snagm-color"
          onClick={() => handleClick()}
        >
          {uploading ? <Dots color="#ffffff" /> : "Upload New Banner"}
        </Button>
      </Row>
    </Col>
  );
}
