import React, { useState } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { adDocs } from "../config/adDocs";
import { readDocument, writeDocument } from "../services/firestore";
import { makeLink } from "../services/svcFunctions";
import IonIcon from "@reacticons/ionicons";

export default function CardAds() {
  const [adIndex, setAdIndex] = useState(0);
  const [adsList, setAdsList] = useState([]);

  const handleChangeIndex = (event) => {
    setAdIndex(event.target.value);
  };

  const loadAds = async () => {
    const docKey = adDocs[adIndex];
    try {
      const document = await readDocument("ads", docKey);
      if (document) {
        setAdsList(document.adsList);
      }
    } catch (error) {
      alert("Error loading ads: " + error.message);
    }
  };

  const removeAd = async (adID) => {
    const docKey = adDocs[adIndex];
    try {
      const newAdsList = adsList.filter((ad) => ad.id !== adID);
      const newDocument = { adsList: newAdsList };
      await writeDocument("ads", docKey, newDocument);
      setAdsList(newAdsList);
    } catch (error) {
      alert("Error removing ad: " + error.message);
    }
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Manage Ads</h3>
      </Row>
      <Row className="inline-row">
        <Col>
          <Form.Select value={adIndex} onChange={handleChangeIndex}>
            <option value={0}>Ad Set 0</option>
            <option value={1}>Ad Set 1</option>
            <option value={2}>Ad Set 2</option>
            <option value={3}>Ad Set 3</option>
            <option value={4}>Ad Set 4</option>
            <option value={5}>Ad Set 5</option>
            <option value={6}>Ad Set 6</option>
            <option value={7}>Ad Set 7</option>
            <option value={8}>Ad Set 8</option>
            <option value={9}>Ad Set 9</option>
          </Form.Select>
        </Col>
        <Col>
          <Button className="snagm-color" onClick={() => loadAds()}>
            Load Ads
          </Button>
        </Col>
      </Row>
      <Row className="padded-row big-top">
        <hr />
      </Row>
      {adsList.length === 0 && (
        <Row>
          <p className="center-text">NO ADS FOUND</p>
        </Row>
      )}
      {adsList.map((ad, index) => {
        return (
          <Row className="padded-row" key={index}>
            <Row>
              {ad.mode === "test" ? (
                <Col>
                  <h5>[TEST] {ad.nickname}</h5>
                </Col>
              ) : (
                <Col>
                  <h5>{ad.nickname}</h5>
                </Col>
              )}
              <Col className="delete-column">
                <IonIcon
                  className={"delete-icon"}
                  name="trash"
                  onClick={() => removeAd(ad.id)}
                />
              </Col>
            </Row>
            <Row>
              <p className="smaller-text">Created: {ad.created}</p>
            </Row>
            <Row>
              <a href={ad.link} target="_blank" rel="noreferrer">
                {ad.link}
              </a>
            </Row>
            <Row className="padded-row v-top-5">
              <Image src={makeLink("images", ad.file)} />
            </Row>
            <Row className="padded-row big-top">
              <hr />
            </Row>
          </Row>
        );
      })}
    </Col>
  );
}
