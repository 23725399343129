const apiKey =
  "js-0NOmIK6x0S0MqyGZgtgXr9SHpKTH73w00DEJLfV1VBKCN8XB37sz8QAeTw6EO6Rh";

export async function getZipRadius(zipCode) {
  if (zipCode === "99997") return ["99997"];
  const radius = 15;
  const url = `https://www.zipcodeapi.com/rest/${apiKey}/radius.json/${zipCode}/${radius}/mile?minimal`;
  const response = await fetch(url);
  const zipData = await response.json();
  return zipData.zip_codes;
}

export async function getZipsByTarget(zipCode, radius) {
  if (zipCode === "99997") return ["99997"];
  const url = `https://www.zipcodeapi.com/rest/${apiKey}/radius.json/${zipCode}/${radius}/mile?minimal`;
  const response = await fetch(url);
  const zipData = await response.json();
  return zipData.zip_codes;
}

export async function getCityFromZip(zipCode) {
  try {
    const url = `https://www.zipcodeapi.com/rest/${apiKey}/info.json/${zipCode}/degrees`;
    const response = await fetch(url);
    const zipData = await response.json();
    const cityName = zipData.city + " " + zipData.state;
    return cityName;
  } catch (error) {
    return null;
  }
}

export async function getZipsFromCity(city, state) {
  const url = `https://www.zipcodeapi.com/rest/${apiKey}/city-zips.json/${city}/${state}`;
  const response = await fetch(url);
  const zipData = await response.json();
  return zipData.zip_codes;
}

export function mergeZips(masterZips, newZips) {
  const mergedZips = masterZips.concat(newZips);
  const uniqueZips = [...new Set(mergedZips)];
  return uniqueZips;
}
