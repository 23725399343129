import React, { useState } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { getCollection, deleteDocument } from "../services/firestore";
import { niceDate } from "../services/svcFunctions";
import IonIcon from "@reacticons/ionicons";

export default function CardFeedback() {
  const [docs, setDocs] = useState([]);

  const loadDocs = async () => {
    const results = await getCollection("feedback");
    results.sort((a, b) => b.submitted - a.submitted);
    results.splice(20);
    setDocs(results);
  };

  const removeItem = async (id) => {
    await deleteDocument("feedback", id);
    const newDocs = docs.filter((doc) => doc.id !== id);
    setDocs(newDocs);
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Feedback</h3>
      </Row>
      <Row className="campaign-row big-bottom">
        <Button onClick={() => loadDocs()} className="button-campaign">
          Load Records
        </Button>
      </Row>
      {docs.map((doc) => (
        <React.Fragment>
          <Row key={doc.id}>
            <Col>
              <p>{niceDate(doc.submitted)}</p>
            </Col>
            <Col className="delete-column">
              <IonIcon
                className={"delete-icon"}
                name="trash"
                onClick={() => removeItem(doc.id)}
              />
            </Col>
            <p>{doc.data.text}</p>
          </Row>
          <Row className="padded-row v-top-10">
            <hr />
          </Row>
        </React.Fragment>
      ))}
    </Col>
  );
}
