import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import Button from "react-bootstrap/Button";
import CardClients from "./cardClients";
import CardApprovals from "./cardApprovals";
import CardAddClient from "./cardAddClient";
import CardAddAdvertiser from "./cardAddAdvertiser";
import CardAddDesigner from "./cardAddDesigner";
import CardFeedback from "./cardFeedback";
import CardDeployCity from "./cardDeployCity";

export default function DashHome() {
  const navigate = useNavigate();
  const [deployment, setDeployment] = useState("");

  const updateDeployment = (e) => {
    const cityState = e.target.value;
    setDeployment(cityState);
    localStorage.setItem("deployment", cityState);
  };

  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardApprovals />
          <Row className="padded-row big-top">
            <Button
              variant="secondary"
              size="lg"
              onClick={() => navigate("/admin/ads")}
            >
              Manage Ads
            </Button>
          </Row>
        </Col>
        <Col>
          <CardClients
            deployment={deployment}
            updateDeployment={updateDeployment}
          />
        </Col>
        <Col>
          <CardAddClient />
        </Col>
        <Col>
          <CardAddAdvertiser />
        </Col>
        <Col>
          <CardAddDesigner />
        </Col>
        <Col>
          <CardFeedback />
        </Col>
        <Col>
          <CardDeployCity />
        </Col>
      </Row>
    </Container>
  );
}
