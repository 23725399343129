import React, { useState } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Dots } from "react-activity";
import { getZipsByTarget, mergeZips } from "../services/svcZips";
import {
  createDocument,
  updateDocument,
  readDocument,
} from "../services/firestore";
import { searchDeployments } from "../services/svcDeployments";

const statesList = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export default function CardDeployCity() {
  const [selectedState, setSelectedState] = useState("AL");
  const [selectedCity, setSelectedCity] = useState("");
  const [deploymentZips, setDeploymentZips] = useState([]);
  const [gettingZips, setGettingZips] = useState(false);
  const [loadingDeployment, setLoadingDeployment] = useState(false);
  const [deployed, setDeployed] = useState("standby");
  const [deployedZipCount, setDeployedZipCount] = useState(0);
  const [targetZip, setTargetZip] = useState("");
  const [targetRadius, setTargetRadius] = useState(10);
  const [deployment, setDeployment] = useState({});

  const resetForm = () => {
    setSelectedCity("");
    setSelectedState("AL");
    setDeploymentZips([]);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const handleZipChange = (e) => {
    setTargetZip(e.target.value);
  };

  const handleRadiusChange = (e) => {
    setTargetRadius(e.target.value);
  };

  const loadDeployment = async () => {
    setLoadingDeployment(true);
    //-- first try to read any current deployment record
    const deployRecord = await searchDeployments(
      selectedCity + " " + selectedState
    );
    if (deployRecord) {
      setDeployed("production");
      setDeployment(deployRecord);
      const deployData = deployRecord.data;
      setDeployedZipCount(deployData.masterZips.length);
    } else {
      setDeployed("pending");
    }
    setLoadingDeployment(false);
  };

  const handleGetZips = async () => {
    setGettingZips(true);
    const zips = await getZipsByTarget(targetZip, targetRadius);
    if (zips) {
      setDeploymentZips(zips);
    } else {
      alert("ERROR WITH ZIPS API");
    }
    setGettingZips(false);
  };

  const createDeployment = () => {
    const cityState = selectedCity + " " + selectedState;
    const zipData = {
      clients: 0,
      masterZips: deploymentZips,
      activeZips: [],
    };
    const deploymentRecord = {
      status: "pending",
      cityState: cityState,
      city: selectedCity,
      state: selectedState,
      data: zipData,
    };
    const result = createDocument("deployments", deploymentRecord);
    if (result) {
      alert("Deployment Created");
      resetForm();
    } else {
      alert("Deployment Failed");
    }
  };

  const updateDeployment = async () => {
    const newRecord = { ...deployment };
    const newZips = mergeZips(deployment.data.masterZips, deploymentZips);
    newRecord.data.masterZips = newZips;
    const result = await updateDocument(
      "deployments",
      deployment.id,
      newRecord
    );
    if (result) {
      alert("Deployment Updated");
      resetForm();
    } else {
      alert("Deployment Update Failed");
    }
  };

  const handleDeploy = () => {
    if (deployed === "standby") return;
    if (deployed === "pending") {
      createDeployment();
    } else {
      updateDeployment();
    }
  };

  const handleRelease = async () => {
    if (deployed !== "production") return;
    //-- concat all master zips with the active zips in the deployment record
    const currentDeployment = { ...deployment };
    const currentDeploymentData = currentDeployment.data;
    const oldActives = [...currentDeploymentData.activeZips];
    const newActives = mergeZips(oldActives, deployment.data.masterZips);
    const newRecord = { ...deployment };
    newRecord.status = "production";
    newRecord.data.activeZips = newActives;
    await updateDocument("deployments", deployment.id, newRecord);
    //-- update the global production document
    const globalDoc = await readDocument("global", "production");
    const globalActives = globalDoc.zips;
    const newGlobalActives = globalActives.concat(newActives);
    const uniqueGlobalActives = [...new Set(newGlobalActives)];
    globalDoc.zips = uniqueGlobalActives;
    await updateDocument("global", "production", globalDoc);
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Deploy New City</h3>
      </Row>
      <Row className="padded-row">
        <Col className="grow-3">
          <Form.Control
            placeholder="City Name"
            value={selectedCity}
            onChange={(e) => handleCityChange(e)}
          />
        </Col>
        <Col>
          <Form.Select
            value={selectedState}
            onChange={(e) => handleStateChange(e)}
          >
            {statesList.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="padded-row">
        <Button className="button-campaign" onClick={() => loadDeployment()}>
          {loadingDeployment ? <Dots /> : "LOAD DEPLOYMENT"}
        </Button>
      </Row>
      {deployed !== "standby" && (
        <Row className="padded-row">
          <h5 style={{ textAlign: "center", marginTop: 20 }}>
            {deployed === "pending"
              ? "READY TO CREATE"
              : deployedZipCount + " MASTER ZIPS ON FILE"}
          </h5>
        </Row>
      )}
      <Row className="padded-row big-top">
        <Col className="grow-3">
          <Form.Control
            placeholder="Target Zip Code"
            value={targetZip}
            onChange={(e) => handleZipChange(e)}
          />
        </Col>
        <Col>
          <Form.Control
            placeholder="Target Radius"
            value={targetRadius}
            onChange={(e) => handleRadiusChange(e)}
          />
        </Col>
      </Row>
      <Row className="padded-row">
        <Button className="button-campaign" onClick={() => handleGetZips()}>
          {gettingZips ? <Dots /> : "GET ZIPS"}
        </Button>
      </Row>
      <Row className="padded-row">
        <h5 style={{ textAlign: "center", marginTop: 20 }}>
          {deploymentZips.length} zip codes loaded
        </h5>
      </Row>
      <Row className="padded-row">
        <Button
          disabled={deploymentZips.length === 0}
          onClick={() => handleDeploy()}
          className="button-campaign"
        >
          APPEND ZIPS
        </Button>
      </Row>
      <Row className="padded-row">
        <Button
          disabled={deployed !== "production"}
          onClick={() => handleRelease()}
          className="button-campaign"
        >
          DEPLOY TO PRODUCTION
        </Button>
      </Row>
    </Col>
  );
}
