import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardClientManage from "./cardClientManage";
import { getClient } from "../services/svcClient";

export default function DashClient() {
  const [params] = useSearchParams();
  const [clientData, setClientData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getClientData = async (id) => {
      const client = await getClient(id);
      setClientData(client);
      setLoading(false);
    };
    const id = params.get("clientID");
    if (id) {
      getClientData(id);
    }
  }, [params]);

  if (loading) {
    return (
      <Container fluid>
        <Header />
        <Row className="grid-container main-row"></Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardClientManage clientData={clientData} />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
