import React, { useState } from "react";
import { validateURL } from "../services/svcFunctions";
import { createAdvertiser } from "../services/svcClient";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Dots } from "react-activity";

const emptyForm = {
  clientName: "",
  clientURL: "",
  clientZIP: "",
  contactName: "",
  contactPhone: "",
  contactEmail: "",
};

export default function CardAddClient() {
  const [formData, setFormData] = useState(emptyForm);
  const [submitReady, setSubmitReady] = useState(false);
  const [processing, setProcessing] = useState(false);

  const changeFormData = (e) => {
    const newFormData = { ...formData };
    newFormData[e.target.id] = e.target.value;
    setFormData(newFormData);
    checkSubmitReady(newFormData)
      ? setSubmitReady(true)
      : setSubmitReady(false);
  };

  const checkSubmitReady = (data) => {
    if (data.clientName.length < 3) return false;
    return validateURL(data.clientURL);
  };

  const submitForm = async () => {
    setProcessing(true);
    await createAdvertiser(formData);
    setFormData(emptyForm);
    setProcessing(false);
    setSubmitReady(false);
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Add an Advertiser</h3>
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="clientName"
          value={formData.clientName}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Business Name"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="clientURL"
          value={formData.clientURL}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Business Website - https://www.example.com"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="clientZIP"
          value={formData.clientZIP}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Business ZIP Code"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="contactName"
          value={formData.contactName}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Contact Name"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="contactPhone"
          value={formData.contactPhone}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Contact Phone"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="contactEmail"
          value={formData.contactEmail}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Contact Email"
        />
      </Row>
      <Row className="campaign-row">
        {submitReady && !processing && (
          <Button className="button-campaign" onClick={() => submitForm()}>
            Create Advertiser
          </Button>
        )}
        {submitReady && processing && (
          <Button className="button-campaign">
            <Dots color="white" size={20} />
          </Button>
        )}
        {!submitReady && (
          <Button className="button-campaign" variant="secondary" disabled>
            Create Advertiser
          </Button>
        )}
      </Row>
    </Col>
  );
}
