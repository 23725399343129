import React, { useState, useEffect } from "react";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardReviewApprove from "./cardReviewApprove";
import { getCollection } from "../services/firestore";

export default function DashApprove() {
  const [approvalsList, setApprovalsList] = useState([]);
  const [approval, setApproval] = useState({});
  const [approvalIndex, setApprovalIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getApprovals = async () => {
      const approvalsData = await getCollection("approvals");
      setApprovalsList(approvalsData);
      setApproval(approvalsData[0]);
      setLoading(false);
    };
    getApprovals();
  }, []);

  const nextApproval = () => {
    if (approvalIndex < approvalsList.length - 1) {
      const newApprovalIndex = approvalIndex + 1;
      setApprovalIndex(newApprovalIndex);
      setApproval(approvalsList[newApprovalIndex]);
    }
  };

  if (loading) {
    return (
      <Container fluid>
        <Header />
        <Row className="grid-container main-row"></Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardReviewApprove
            approvalCount={approvalsList.length}
            approvalIndex={approvalIndex}
            approval={approval}
            nextApproval={nextApproval}
          />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
