import {
  readDocument,
  updateDocument,
  writeDocument,
  createDocument,
  queryCollection,
} from "./firestore";
import { genID, genScancode } from "./svcFunctions";
import { getCityFromZip } from "./svcZips";

export async function getClient(id) {
  const clientRecord = await readDocument("clients", id);
  const clientData = clientRecord.data;
  return clientData;
}

export async function getClientList(zipCode) {
  const clientList = [];
  const clientQuery = await queryCollection("clients", "zip", zipCode);
  clientQuery.forEach((doc) => {
    const client = doc.data;
    clientList.push(client);
  });
  return clientList;
}

export async function getClientsbyDeployment(cityState) {
  const clientList = [];
  const clientQuery = await queryCollection("clients", "deployment", cityState);
  clientQuery.forEach((doc) => {
    const client = doc.data;
    clientList.push(client);
  });
  return clientList;
}

export async function lookupClient(id) {
  const clientRecord = await readDocument("client-lookup", id);
  const clientData = clientRecord.data;
  return clientData;
}

export async function updateClient(id, data) {
  try {
    const clientRecord = {
      zip: data.clientZIP,
      data: data,
      deployment: data.cityState,
    };
    await updateDocument("clients", id, clientRecord);
    return "success";
  } catch (err) {
    return err.message;
  }
}

export async function createClient(data) {
  let result = "OK";
  try {
    const docKey = data.clientID;
    const clientRecord = {
      zip: data.clientZIP,
      deployment: data.cityState,
      data: data,
    };
    await writeDocument("clients", docKey, clientRecord);
    const lookupData = {
      clientID: data.clientID,
      clientName: data.clientName,
      logo: data.logo,
      url: data.url,
    };
    const lookupRecord = { data: lookupData };
    await writeDocument("client-lookup", docKey, lookupRecord);
    const tokensRecord = { tokens: [] };
    await writeDocument("notifySubs", docKey, tokensRecord);
  } catch (err) {
    result = "ERROR: " + err.message;
  }
  return result;
}

export async function incrementZipStatsNewClient(zipcode) {
  console.log("INCREMENT CLIENT STATS: ", zipcode);
  const zipStatsRecord = await readDocument("zipstats", zipcode);
  if (zipStatsRecord) {
    zipStatsRecord.data.clients++;
    await writeDocument("zipstats", zipcode, zipStatsRecord);
  } else {
    const newZipStats = {
      zip: zipcode,
      clients: 1,
      users: 0,
    };
    const docRecord = { data: newZipStats };
    await createDocument("zipstats", docRecord);
    console.log("NEW ZIP STATS: ", docRecord);
  }
}

export async function getDeploymentID(zipcode) {
  const cityState = await getCityFromZip(zipcode);
  const deployQuery = await queryCollection(
    "deployments",
    "cityState",
    cityState
  );
  console.log("DEPLOY QUERY: ", deployQuery);
  let docID = null;
  deployQuery.forEach((doc) => {
    docID = doc.id;
  });
  return docID;
}

export async function createAdvertiser(data) {
  const radiusList = [5, 10, 15, 20, 25];
  const zipRadius = {};
  const zipCode = data.clientZIP;
  const clientID = genID(12);
  const apiKey =
    "js-0NOmIK6x0S0MqyGZgtgXr9SHpKTH73w00DEJLfV1VBKCN8XB37sz8QAeTw6EO6Rh";
  await Promise.all(
    radiusList.map(async (radius) => {
      const url = `https://www.zipcodeapi.com/rest/${apiKey}/radius.json/${zipCode}/${radius}/mile?minimal`;
      const response = await fetch(url);
      const zipData = await response.json();
      zipRadius[radius] = zipData.zip_codes;
      await new Promise((resolve) => setTimeout(resolve, 1000));
    })
  );
  console.log("ZIP RADIUS: ", zipRadius);
  data.clientID = clientID;
  data.zipRadius = zipRadius;
  const advertiserRecord = { zip: zipCode, data: data };
  await writeDocument("advertisers", clientID, advertiserRecord);
  return null;
}

export async function createDesigner(data) {
  //-- create the designer record
  const designerData = {
    avgHours: 0,
    avgRating: 5,
    completedOrders: [],
    displayName: data.contactName,
    email: data.contactEmail,
    pastMonthPayouts: [],
  };
  const designerRecord = { data: designerData };
  const docID = await createDocument("designers", designerRecord);
  //-- create the newUser record
  const newUserData = {
    added: new Date().getTime(),
    addedBy: "office",
    clientID: "snagm",
    designer: docID,
    clientName: "Snagm Design Portal",
    email: data.contactEmail,
    name: data.contactName,
    status: "Active",
    tempPwd: genScancode(8),
  };
  const newUserRecord = { data: newUserData };
  await createDocument("newUser", newUserRecord);
  return null;
}

export async function addScancode(record) {
  const docKey = genID(12);
  await writeDocument("scancodes", docKey, record);
  return docKey;
}

export async function addAsset(id, record) {
  const client = await getClient(id);
  if ("assets" in client) {
    client.assets.unshift(record);
  } else {
    client.assets = [record];
  }
  await updateClient(id, client);
  return null;
}

export async function addFirstUser(record) {
  const dataRecord = { data: record };
  await createDocument("newUser", dataRecord);
  return null;
}
