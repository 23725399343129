import { getApp } from "firebase/app";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  setDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  query,
  collection,
  where,
  getCountFromServer,
  arrayUnion,
} from "firebase/firestore";

export async function writeDocument(name, key, data) {
  try {
    const app = getApp();
    if (app) {
      const db = getFirestore(app);
      await setDoc(doc(db, name, key), data);
      return data;
    }
  } catch (error) {
    console.log("writeDocument: ERROR:", error);
  }
  return null;
}

export async function createDocument(name, data) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docResult = await addDoc(collection(db, name), data);
    return docResult.id;
  }
  return null;
}

export async function readDocument(name, key) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
  }
  return null;
}

export async function updateDocument(name, key, data) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    await setDoc(docRef, data, { merge: true });
    return data;
  }
  return null;
}

export async function mergeDocument(name, key, data) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    await updateDoc(docRef, data, { merge: true });
    return data;
  }
  return null;
}

export async function deleteDocument(name, key) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    await deleteDoc(docRef);
  }
  return null;
}

export async function queryCollection(collectionName, attribName, attribValue) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const q = query(
      collection(db, collectionName),
      where(attribName, "==", attribValue)
    );
    const querySnapshot = await getDocs(q);
    const results = [];
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      docData.id = doc.id;
      results.push(docData);
    });
    return results;
  }
  return null;
}

export async function getCollection(name) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const resultSet = [];
    const snapshot = await getDocs(collection(db, name));
    snapshot.forEach((doc) => {
      const docItem = doc.data();
      docItem.id = doc.id;
      resultSet.push(docItem);
    });
    return resultSet;
  }
}

export async function collectionCount(name) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const coll = collection(db, name);
    const snapshot = await getCountFromServer(coll);
    return snapshot.data().count;
  }
  return 0;
}

export async function unionArray(name, key, attrib, data) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    await updateDoc(docRef, {
      [attrib]: arrayUnion(data),
    });
  }
  return "OK";
}
