import React, { useState, useEffect } from "react";
import { initializeApp, getApps } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { BrowserRouter } from "react-router-dom";
import { firebaseConfig } from "./config/firebase";
import { AppContext } from "./appContext";
import MainStack from "./components/mainStack";

export default function App() {
  const [user, setUser] = useState();

  useEffect(() => {
    let app;
    if (getApps.length === 0) {
      console.log("FIREBASE INIT");
      app = initializeApp(firebaseConfig);
    }

    function onAuthChange() {
      const auth = getAuth(app);
      return onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log("LOADED USER:", user.uid);
          setUser(user);
          setPersistence(auth, browserSessionPersistence);
        } else {
          console.log("NO USER");
          setUser();
        }
      });
    }
    const unsubscribe = onAuthChange();
    return () => unsubscribe();
  }, []);

  return (
    <AppContext.Provider value={{ user }}>
      <BrowserRouter>
        <MainStack />
      </BrowserRouter>
    </AppContext.Provider>
  );
}
