import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import { getImageURL } from "../services/svcImages";
import { services } from "../config/typeNames";
import { deleteDocument, writeDocument } from "../services/firestore";
import { lookupClient } from "../services/svcClient";

export default function CardReviewApprove(props) {
  const navigate = useNavigate();
  const { approvalCount, approvalIndex, approval, nextApproval } = props;
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(true);
  const [processingApproval, setProcessingApproval] = useState(false);
  const [processingRejection, setProcessingRejection] = useState(false);
  const [readyReject, setReadyReject] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  useEffect(() => {
    const loadApproval = async () => {
      const url = await getImageURL(approval.data.file);
      const clientLookup = await lookupClient(approval.data.clientID);
      if (clientLookup.logo === "placeholder-logo.jpg") {
        alert("no client logo set - do not approve yet");
      }
      setImageURL(url);
      setLoading(false);
      setProcessingApproval(false);
    };
    loadApproval();
  }, [approval]);

  const handleProcessApproval = async () => {
    setProcessingApproval(true);
    const newCoupon = { ...approval.data };
    newCoupon.phase = "active";
    const newCouponRecord = {
      data: newCoupon,
    };
    const newCouponKey = newCoupon.clientID + "-" + newCoupon.type;
    try {
      await writeDocument("approved", newCouponKey, newCouponRecord);
      await deleteDocument("approvals", newCouponKey);
    } catch (error) {
      console.log("handleProcessApproval: ERROR:", error);
    }
    setProcessingApproval(false);
    if (approvalIndex === approvalCount - 1) {
      navigate("/admin");
    }
    nextApproval();
  };

  const handleProcessRejection = async () => {
    setProcessingRejection(true);
    const newCoupon = { ...approval.data };
    newCoupon.phase = "rejected";
    newCoupon.rejectReason = rejectReason;
    const newCouponRecord = {
      data: newCoupon,
    };
    const newCouponKey = newCoupon.clientID + "-" + newCoupon.type;
    try {
      await writeDocument("rejected", newCouponKey, newCouponRecord);
      await deleteDocument("approvals", newCouponKey);
    } catch (error) {
      console.log("handleProcessRejection: ERROR:", error);
    }
    setProcessingRejection(false);
    if (approvalIndex === approvalCount - 1) {
      navigate("/admin");
    }
    nextApproval();
  };

  const handleRejectReason = (e) => {
    setRejectReason(e.target.value);
    if (e.target.value.length > 0) {
      setReadyReject(true);
    } else {
      setReadyReject(false);
    }
  };

  const handleSkip = () => {
    if (approvalIndex === approvalCount - 1) {
      navigate("/admin");
    }
    nextApproval();
  };

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Review and Approve</h3>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Review and Approve</h3>
        <h6>
          Request {approvalIndex + 1} of {approvalCount}
        </h6>
      </Row>
      <Row>
        <h4 className="snagm-font text-center">{approval.data.clientName}</h4>
        <h6 className="text-center">{services[approval.data.type]}</h6>
      </Row>
      <Row>
        <Image src={imageURL} />
      </Row>
      <Row className="v-top-10">
        <h4 className="text-center">{approval.data.title}</h4>
        <h5 className="text-center">{approval.data.tag}</h5>
        <p className="text-center">{approval.data.terms}</p>
      </Row>
      {processingApproval ? (
        <Row className="campaign-row v-top-10 big-bottom">
          <Button disabled variant="success">
            ONE MOMENT...
          </Button>
        </Row>
      ) : (
        <Row className="campaign-row v-top-10 big-bottom">
          <Button onClick={() => handleProcessApproval()} variant="success">
            APPROVE TO PUBLISH
          </Button>
        </Row>
      )}

      <Row className="padded-row">
        <Form.Select value={rejectReason} onChange={handleRejectReason}>
          <option value="">Rejection Reason:</option>
          <option value="Not Family Friendly">Not Family Friendly</option>
          <option value="Low Image Quality">Low Image Quality</option>
          <option value="Prohibited Content">Prohibited Content</option>
          <option value="Low Value">Low Value</option>
        </Form.Select>
      </Row>
      <Row className="campaign-row v-top-10 big-bottom">
        <Button
          disabled={!readyReject || processingRejection}
          onClick={() => handleProcessRejection()}
          variant="danger"
        >
          REJECT
        </Button>
      </Row>
      <Row>
        <Button onClick={() => handleSkip()} size="sm" variant="link">
          <p className="text-center">Skip For Now</p>
        </Button>
      </Row>
    </Col>
  );
}
