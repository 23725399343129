import { readDocument, queryCollection, updateDocument } from "./firestore";
import { getCityFromZip } from "./svcZips";

export async function getDeploymentsbyStatus(statusCode) {
  const deploys = await queryCollection("deployments", "status", statusCode);
  if (deploys) {
    return deploys;
  } else {
    return [];
  }
}

export async function getGlobalDeployments() {
  const deploymentsDoc = await readDocument("global", "deployments");
  return deploymentsDoc.data;
}

export async function getDeployment(zipcode) {
  const cityState = await getCityFromZip(zipcode);
  if (!cityState) {
    return null;
  }
  const deployQuery = await queryCollection(
    "deployments",
    "cityState",
    cityState
  );
  if (!deployQuery) {
    return null;
  }
  if (deployQuery.length === 0) {
    return null;
  }
  const deployRecord = deployQuery[0];
  const deployment = {};
  deployment.id = deployRecord.id;
  deployment.cityState = deployRecord.cityState;
  return deployment;
}

export async function updateDeployments(id, radius) {
  console.log("UPDATING DEPLOYMENTS: ", id);
  let result = "OK";
  //-- get the deployments document
  try {
    const deploymentDoc = await readDocument("deployments", id);
    //-- add the radius zips to the activeZips array, no duplicates
    const currentActives = deploymentDoc.data.activeZips;
    const newActives = currentActives.concat(radius);
    const uniqueActives = [...new Set(newActives)];
    deploymentDoc.data.activeZips = uniqueActives;
    deploymentDoc.data.clients++;
    await updateDocument("deployments", id, deploymentDoc);
    //-- if we are in production, update the global production document
    if (deploymentDoc.status === "production") {
      console.log("ALSO UPDATING GLOBAL PRODUCTION");
      const globalDoc = await readDocument("global", "production");
      const globalActives = globalDoc.zips;
      const newGlobalActives = globalActives.concat(radius);
      const uniqueGlobalActives = [...new Set(newGlobalActives)];
      globalDoc.zips = uniqueGlobalActives;
      await updateDocument("global", "production", globalDoc);
    }
  } catch (err) {
    result = "ERROR: " + err.message;
  }
  return result;
}

export async function searchDeployments(cityStateData) {
  const deployQuery = await queryCollection(
    "deployments",
    "cityState",
    cityStateData
  );
  if (!deployQuery) {
    return null;
  }
  if (deployQuery.length === 0) {
    return null;
  }
  const deployRecord = deployQuery[0];
  return deployRecord;
}
