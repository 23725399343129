import React, { useContext } from "react";
import { AppContext } from "../appContext";
import { Routes, Route } from "react-router-dom";
import Login from "./login";
import Forgot from "./forgot";
import NoPage from "./noPage";
import AdminStack from "./adminStack";
import { ProtectedRoute } from "./protected";

export default function MainStack() {
  const myContext = useContext(AppContext);
  const user = myContext.user;
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="login" element={<Login />} />
      <Route path="forgot" element={<Forgot />} />
      <Route element={<ProtectedRoute user={user} />}>
        <Route path="admin/*" element={<AdminStack />} />
      </Route>
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
}
