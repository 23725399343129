import React, { useState, useContext, useRef } from "react";
import { AppContext } from "../appContext";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { adDocs } from "../config/adDocs";
import { genID, makeISO, makeLink } from "../services/svcFunctions";
import { readDocument, writeDocument } from "../services/firestore";
import { uploadImage } from "../services/svcImages";

const emptyAd = {
  nickname: "",
  link: "",
  file: "",
  style: "ad",
};

export default function CardNewAd() {
  const myContext = useContext(AppContext);
  const user = myContext.user;
  const [adIndex, setAdIndex] = useState(0);
  const [adObject, setAdObject] = useState(emptyAd);
  const [imageLink, setImageLink] = useState("");
  const [uploading, setUploading] = useState(false);
  const [adMode, setAdMode] = useState("public");
  const inputRef = useRef(null);

  const handleChangeIndex = (event) => {
    setAdIndex(event.target.value);
  };

  const handleChangeAdMode = (event) => {
    setAdMode(event.target.value);
  };

  const changeFormData = (newText) => {
    const newAd = { ...adObject };
    newAd[newText.target.id] = newText.target.value;
    if (newText.target.id === "file") {
      const newLink = makeLink("images", newText.target.value);
      setImageLink(newLink);
    }
    setAdObject(newAd);
  };

  const handleUpload = async (event) => {
    setUploading(true);
    const fileObj = event.target.files[0];
    try {
      const filename = await uploadImage(fileObj);
      const newAd = { ...adObject };
      newAd.file = filename;
      setAdObject(newAd);
      const newLink = makeLink("images", filename);
      setImageLink(newLink);
    } catch (error) {
      alert("ERROR uploading image: " + error.message);
    }
    setUploading(false);
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const saveAd = async () => {
    //-- set up the new ad object
    const newAd = { ...adObject };
    newAd.id = genID(10);
    const starts = new Date();
    starts.setDate(starts.getDate() + 1);
    newAd.starts = makeISO(starts);
    const endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + 10);
    newAd.ends = makeISO(endDate);
    newAd.created = makeISO(new Date());
    newAd.createdBy = user.email;
    newAd.mode = adMode;
    //-- get the current ads list
    const docKey = adDocs[adIndex];
    const document = await readDocument("ads", docKey);
    if (document) {
      const newAdsList = [...document.adsList];
      newAdsList.push(newAd);
      document.adsList = newAdsList;
      await writeDocument("ads", docKey, document);
      setAdObject(emptyAd);
      setImageLink("");
    } else {
      const newAdsList = [newAd];
      const newDocument = { adsList: newAdsList };
      await writeDocument("ads", docKey, newDocument);
      setAdObject(emptyAd);
      setImageLink("");
    }
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Publish New Ad</h3>
      </Row>
      <Row className="padded-row big-top small-bottom">
        <Form.Control
          id="nickname"
          value={adObject.nickname}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Ad Nickname"
        />
      </Row>
      <Row className="padded-row small-bottom">
        <Form.Control
          id="link"
          value={adObject.link}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Ad URL Link"
        />
      </Row>
      <Row className="padded-row small-bottom">
        <Form.Select value={adMode} onChange={handleChangeAdMode}>
          <option value="public">Public</option>
          <option value="test">Test</option>
        </Form.Select>
      </Row>
      {imageLink !== "" && (
        <Row className="big-top">
          <Image src={imageLink} />
        </Row>
      )}
      <Row className="padded-row">
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          onChange={handleUpload}
        />
        <Button
          disabled={uploading}
          onClick={() => handleClick()}
          className="snagm-color big-top"
        >
          Upload Image
        </Button>
      </Row>
      <Row className="big-top">
        <hr />
      </Row>
      <Row className="inline-row">
        <Col>
          <Form.Select value={adIndex} onChange={handleChangeIndex}>
            <option value={0}>Ad Set 0</option>
            <option value={1}>Ad Set 1</option>
            <option value={2}>Ad Set 2</option>
            <option value={3}>Ad Set 3</option>
            <option value={4}>Ad Set 4</option>
            <option value={5}>Ad Set 5</option>
            <option value={6}>Ad Set 6</option>
            <option value={7}>Ad Set 7</option>
            <option value={8}>Ad Set 8</option>
            <option value={9}>Ad Set 9</option>
          </Form.Select>
        </Col>
        <Col>
          <Button className="snagm-color" onClick={() => saveAd()}>
            Save Ad
          </Button>
        </Col>
      </Row>
    </Col>
  );
}
