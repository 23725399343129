import React, { useState, useEffect } from "react";
import { uploadScancode } from "../services/svcImages";
import { genID, genScancode, isoToday } from "../services/svcFunctions";
import {
  createClient,
  addScancode,
  addAsset,
  addFirstUser,
} from "../services/svcClient";
import { getDeployment, updateDeployments } from "../services/svcDeployments";
import { getZipRadius } from "../services/svcZips";
import { QRCode } from "react-qrcode-logo";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

const logoURL =
  "https://storage.googleapis.com/snagm-b6068.appspot.com/scancodes/scan-logo.png";

const emptyForm = {
  inviteCode: "",
  clientName: "",
  clientURL: "",
  clientZIP: "",
  contactName: "",
  contactTitle: "",
  contactPhone: "",
  contactEmail: "",
  mailingAddr1: "",
  mailingAddr2: "",
  mailingCity: "",
  mailingState: "",
  mailingZIP: "",
  scanCode: "",
};
const scanPrefix = "https://snagm.com/s/";

export default function CardAddClient() {
  const [qrCodeText, setQRCodeText] = useState("");
  const [formData, setFormData] = useState(emptyForm);
  const [submitReady, setSubmitReady] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    resetForm();
  }, []);

  const resetForm = () => {
    setShowAlert(false);
    const newFormData = { ...emptyForm };
    const initScanCode = genScancode(5);
    newFormData.scanCode = initScanCode;
    const scanLink = scanPrefix + initScanCode;
    //-- OVERRIDE QR CODE HERE
    //setQRCodeText("https://snagm.com/demo");
    setQRCodeText(scanLink);
    setFormData(newFormData);
    setSubmitReady(false);
  };

  const checkExistingUser = async () => {
    const data = {
      email: formData.contactEmail,
    };
    const url =
      "https://us-central1-snagm-b6068.cloudfunctions.net/checkExistingUser";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    });
    const results = await response.json();
    if (results.foundUser === "true") {
      setShowAlert(true);
      return true;
    } else {
      setShowAlert(false);
      return false;
    }
  };

  const checkExistingScancode = async () => {
    //-- query the scancode collection to see if the scancode is already in use
  };

  const changeFormData = (e) => {
    setShowAlert(false);
    const newFormData = { ...formData };
    newFormData[e.target.id] = e.target.value;
    setFormData(newFormData);
    const scanLink = scanPrefix + formData.scanCode;
    setQRCodeText(scanLink);
    checkSubmitReady(newFormData)
      ? setSubmitReady(true)
      : setSubmitReady(false);
  };

  const checkSubmitReady = (data) => {
    if (data.clientName.length < 3) return false;
    if (data.contactEmail.length < 5) return false;
    if (data.scanCode.length !== 5) return false;
    return true;
  };

  const newClient = async () => {
    setSubmitReady(false);
    let deployID = null;
    let cityState = "ERROR";

    //--first check to see if the email is already in use
    const emailExists = await checkExistingUser();
    if (emailExists) {
      alert("Email Address Already in Use");
      return;
    }

    //-- then check to see if the scancode is already in use
    const scancodeExists = await checkExistingScancode();
    if (scancodeExists) {
      alert("Scan Code Already in Use");
      return;
    }

    //-- then get the deployment ID to make sure we can add here
    const deployDoc = await getDeployment(formData.clientZIP);
    if (!deployDoc) {
      alert("No Deployment Found for ZIP Code");
      return;
    } else {
      deployID = deployDoc.id;
      cityState = deployDoc.cityState;
    }

    //-- get the zip radius
    const radius = await getZipRadius(formData.clientZIP);
    console.log("NEW CLIENT RADIUS ZIPS: ", radius.length);

    //-- create the client record
    const clientID = genID(12);
    const campaigns = {
      birthday: "inactive",
      discover: "inactive",
      inbox: "inactive",
    };
    const thisMonth = new Date().getMonth() + 1;
    const clientRecord = {
      clientID: clientID,
      clientName: formData.clientName,
      clientZIP: formData.clientZIP,
      cityState: cityState,
      contactName: formData.contactName,
      contactTitle: formData.contactTitle,
      contactPhone: formData.contactPhone,
      contactEmail: formData.contactEmail,
      inviteCode: formData.inviteCode,
      mailingAddr1: formData.mailingAddr1,
      mailingAddr2: formData.mailingAddr2,
      mailingCity: formData.mailingCity,
      mailingState: formData.mailingState,
      mailingZIP: formData.mailingZIP,
      created: isoToday(),
      campaigns: campaigns,
      designCredits: 3,
      designCreditsGranted: thisMonth,
      url: formData.clientURL,
      logo: "placeholder-logo.jpg",
      radius: radius,
      scancode: formData.scanCode,
      subs: 0,
      redeems: 0,
      terms: "Cannot be combined with any other offer.",
    };
    const creationResult = await createClient(clientRecord);
    if (creationResult !== "OK") {
      alert("Error Creating Client: " + creationResult);
      return;
    }

    //-- update the deployment
    const deployResult = await updateDeployments(deployID, radius);
    if (deployResult !== "OK") {
      alert("There was a problem updating the deployment record.");
    }

    //-- create the scancode record
    const filename = await uploadQRCode();
    const scancodeRecord = {
      clientID: clientID,
      imageFile: filename,
      scanCode: formData.scanCode,
    };
    await addScancode(scancodeRecord);
    //-- add the scancode image to the asset list
    const assetRecord = {
      bucket: "scancodes",
      file: filename,
      nickname: "QR CODE - " + formData.scanCode,
    };
    await addAsset(clientID, assetRecord);
    //-- add the first user
    const userRecord = {
      added: new Date().getTime(),
      addedBy: "new-client",
      clientID: clientID,
      clientName: formData.clientName,
      email: formData.contactEmail,
      name: formData.contactName,
      title: formData.contactTitle,
      phone: formData.contactPhone,
      level: "Owner",
      status: "Active",
    };
    await addFirstUser(userRecord);
    resetForm();
  };

  const uploadQRCode = async () => {
    const myDataURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const filename = await uploadScancode(myDataURL);
    return filename;
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Add a Client</h3>
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="inviteCode"
          value={formData.inviteCode}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Invite Code"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="clientName"
          value={formData.clientName}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Restaurant Name"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="clientURL"
          value={formData.clientURL}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Restaurant Website - https://"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="clientZIP"
          value={formData.clientZIP}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Restaurant ZIP Code"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="contactName"
          value={formData.contactName}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Contact Name"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="contactTitle"
          value={formData.contactTitle}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Contact Title"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="contactPhone"
          value={formData.contactPhone}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Contact Phone"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="contactEmail"
          value={formData.contactEmail}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Contact Email"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="mailingAddr1"
          value={formData.mailingAddr1}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Mailing Address 1"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="mailingAddr2"
          value={formData.mailingAddr2}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Mailing Address 2"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="mailingCity"
          value={formData.mailingCity}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Mailing City"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="mailingState"
          value={formData.mailingState}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Mailing State"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="mailingZIP"
          value={formData.mailingZIP}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Mailing ZIP Code"
        />
      </Row>
      <Row className="padded-row">
        <Form.Control
          id="scanCode"
          value={formData.scanCode}
          onChange={(newText) => changeFormData(newText)}
          placeholder="Scan Code"
        />
      </Row>
      <Row className="scan-row">
        <div className="scan-div">
          <QRCode
            id="qrCodeEl"
            size={300}
            value={qrCodeText}
            logoImage={logoURL}
            qrStyle={"dots"}
            removeQrCodeBehindLogo={true}
            ecLevel="M"
            enableCORS={true}
          />
        </div>
      </Row>
      {showAlert && (
        <Row className="padded-row">
          <Alert className="alert-box" variant="danger">
            <p>Email Address Already in Use</p>
          </Alert>
        </Row>
      )}
      <Row className="padded-row">
        {submitReady ? (
          <Button className="button-create" onClick={() => newClient()}>
            Create Client
          </Button>
        ) : (
          <Button className="button-create" variant="secondary" disabled>
            Create Client
          </Button>
        )}
      </Row>
    </Col>
  );
}
