import { getApp } from "firebase/app";
import {
  ref,
  getDownloadURL,
  getStorage,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { genID } from "../services/svcFunctions";

export const getImageURL = async (filename) => {
  const folderPath = "images/" + filename;
  const storage = getStorage(getApp());
  const storageRef = ref(storage, folderPath);
  const imageURL = await getDownloadURL(storageRef);
  return imageURL;
};

export const uploadImage = async (fileObj) => {
  const filename = genID(12) + ".jpg";
  const folderPath = "images/" + filename;
  const storage = getStorage(getApp());
  const storageRef = ref(storage, folderPath);
  await uploadBytes(storageRef, fileObj);
  return filename;
};

export const uploadScancode = async (fileObj) => {
  const filename = genID(12) + ".png";
  const folderPath = "scancodes/" + filename;
  const storage = getStorage(getApp());
  const storageRef = ref(storage, folderPath);
  await uploadString(storageRef, fileObj, "data_url");
  return filename;
};

export const uploadPhotos = async (fileObj) => {
  const filename = genID(12) + ".jpg";
  const folderPath = "photos/" + filename;
  const storage = getStorage(getApp());
  const storageRef = ref(storage, folderPath);
  await uploadBytes(storageRef, fileObj);
  return filename;
};
