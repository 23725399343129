import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getClientsbyDeployment } from "../services/svcClient";
import { getGlobalDeployments } from "../services/svcDeployments";

export default function CardClients(props) {
  const navigate = useNavigate();
  const { deployment, updateDeployment } = props;
  const [clientList, setClientList] = useState([]);
  const [deploymentsList, setDeploymentsList] = useState([]);

  useEffect(() => {
    const getDeployments = async () => {
      const deploys = await getGlobalDeployments();
      setDeploymentsList(deploys);
    };
    getDeployments();
  }, [deployment]);

  const searchClients = async () => {
    const newClientList = await getClientsbyDeployment(deployment);
    newClientList.sort((a, b) => (a.clientName > b.clientName ? 1 : -1));
    setClientList(newClientList);
  };

  const viewClient = (id) => {
    const target = "/admin/client?clientID=" + id;
    navigate(target);
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Client Lookup</h3>
      </Row>
      <Row className="search-row">
        <Col>
          <Form.Control
            as="select"
            onChange={(e) => updateDeployment(e)}
            value={deployment}
          >
            <option value="">- select deployment -</option>
            {deploymentsList.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col>
          <Button onClick={() => searchClients()} className="search-button">
            Load Clients
          </Button>
        </Col>
      </Row>
      <Row className="campaign-row">
        <hr />
      </Row>
      {clientList.length === 0 && (
        <Row>
          <p>-- no clients found</p>
        </Row>
      )}
      {clientList.map((client, index) => (
        <Row key={index} className="results-row">
          <Col>
            <p
              onClick={() => viewClient(client.clientID)}
              className="results-text"
            >
              {client.clientName}
            </p>
          </Col>
        </Row>
      ))}
    </Col>
  );
}
